import React from "react"

import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import DivSeperator from "../components/HomePage/DivSeperator"

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"

const BlogTemplate = ({ data }) => {
  const {
    id,
    title,
    updatedAt,
    description: { description },
    postBody,
    image,
  } = data.contentfulBlog
  const pathToImage = getImage(image)

  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

      [INLINES.HYPERLINK]: (node, children) => {
        if (
          node.data.uri.includes("https://") &&
          !node.data.uri.includes("youtube.com/embed")
        ) {
          return (
            <a href={node.data.uri} target="_blank" rel="noreferrer">
              {node.content[0].value}
            </a>
          )
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <VideoResponsive>
              <iframe
                src={node.data.uri}
                height="315"
                width="420"
                frameBorder="0"
                scrolling="no"
                allowFullScreen={true}
                title="YouTube"
              />
            </VideoResponsive>
          )
        } else {
          return <Link to={node.data.uri}>{node.content[0].value}</Link>
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: node => {
        const imageID = node.data.target.sys.id
        const {
          file: { url },
          title,
        } = postBody.references.find(({ contentful_id: id }) => id === imageID)

        return (
          <>
            {/* <h2>Embedded Asset</h2>
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
            <img src={url} alt={title} /> */}
            <div className="text-center">
              <GatsbyImage
                className="rounded"
                image={node.data.target.gatsbyImageData}
                alt={title}
              />
            </div>
          </>
        )
      },
    },
  }

  // console.log(postBody)
  // console.log(postBody.json)
  // const { bodyRichText } = renderRichText(postBody, options)
  // console.log(renderRichText(postBody, options))

  return (
    <Layout>
      <SEO title={title} description={description} />
      <main>
        <GatsbyImage
          image={pathToImage}
          alt={title}
          layout="fullWidth"
          aspectRatio={16 / 9}
          height="200"
        />

        <Container style={{ maxWidth: "800px" }} className="ps-5 pe-5">
          <Row className="mx-auto pt-5">
            {/* <Col sm={12} md={6} className="pb-5 text-center">
              <GatsbyImage image={pathToImage} alt={title} />
            </Col> */}
            <Col
              sm={12}
              className="pb-5 text-center text-uppercase font-weight-bold"
            >
              <h1>{title}</h1>
              {/* <p>{description}</p> */}
            </Col>
          </Row>
          <DivSeperator />

          <Row className="mx-auto pt-5">
            <Col>
              <article>{postBody && renderRichText(postBody, options)}</article>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query getSingleBlog($title: String) {
    contentfulBlog(title: { eq: $title }, node_locale: { eq: "en-US" }) {
      id
      title
      description {
        description
      }
      updatedAt(formatString: "YYYY/MM/DD")
      postBody {
        raw
        references {
          # ... on Node {
          #   ... on ContentfulAsset {
          contentful_id
          title
          file {
            url
          }
          __typename
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)

          # fixed(width: 1600) {
          #   width
          #   height
          #   src
          #   srcSet
          # }
          #   }
          # }
          # ... on ContentfulBlog {
          #   contentful_id
          #   __typename
          #   title
          # }
        }
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
`
const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  > iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

export default BlogTemplate
